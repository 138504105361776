.log-in-ed {
  padding-top: 28px;
  width: 411px;
  .log-in-ed-img-container > img {
    width: 411px;
    height: 399px;
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .log-in-ed .log-in-ed-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 85vw;
      height: auto !important;
    }
  }
}
