/* In Webseite enthaltenes Stil-Dokument #9 | https://online.edenred.de/ */

#CybotCookiebotDialog {
  border: 8px solid #fff !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
  width: 100% !important;
  padding-left: 76px !important;
  box-sizing: border-box !important;
  margin-bottom: 7px;
}

#CybotCookiebotDialogBody {
  font-family: Montserrat !important;
}

#CybotCookiebotDialogBodyContentTitle {
  font-family: Montserrat !important;
  font-size: 20px;
}

#CybotCookiebotDialogBodyContentText {
  /* font-family: "Montserrat"; */
  font-family: Montserrat !important;
  font-size: 14px;
}

.CybotCookiebotDialogBodyButton {
  padding: 5px 0% !important;
  box-sizing: border-box !important;
  height: auto !important;
  float: left !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: transparent !important;
  font-family: Montserrat !important;
  border: 0 none !important;
  color: #323232 !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  width: 25% !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
  width: 30% !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: #dcf691 !important;
  width: 40% !important;
  margin-right: 5% !important;
}
