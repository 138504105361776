@import '../../../theme/common/variables.scss';

.app-ed-header {
  // padding: 20px 0;
  min-height: $header-height;
  .app-ed-header-nav {
    .app-ed-nav-list {
      .app-ed-nav-menu {
        // padding-left: 20px;
        .app-ed-nav-link {
          // padding-right: 10px;
          height: 40px;
          margin: 10px 0;
          border-radius: 40px;
          &:hover {
            background: $app-header-hover-bg-color;
            -webkit-transition: background-color 500ms ease-in-out;
            -ms-transition: background-color 500ms ease-in-out;
            transition: background-color 500ms ease-in-out;
            .app-ed-nav-icon {
              background: $app-header-hover-bg-color;
              -webkit-transition: background-color 500ms ease-in-out;
              -ms-transition: background-color 500ms ease-in-out;
              transition: background-color 500ms ease-in-out;
            }
            span {
              color: $app-header-hover-text-color;
              // padding: 0 9px;
            }
          }
          .app-ed-nav-icon {
            margin-top: 8px;
            margin-top: 2px;
            margin-bottom: 2px;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 40px;
            & .active {
            }
            &:hover {
              background: $app-header-hover-bg-color;
              color: $app-header-hover-text-color;
            }
            img {
              width: 22px;
              height: auto;
            }
          }
          .app-ed-nav-label {
            padding-left: 5px;
            padding-right: 5px;
            display: flex;
          }

          // span {
          //   padding: 0 9px;
          // }
        }
      }
    }
  }
}
.footer-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-nav button {
  padding: 0 25px;
}
.app-ed-content {
  min-height: calc(100% - ($footer-height));
  margin-bottom: -($footer-height);
}
.app-ed-page {
  min-height: calc(100vh - ($header-height + $footer-height));
  z-index: 1;
  .app-ed-page-content {
    min-height: calc(100vh - ($header-height + $footer-height));
  }
}

.app-ed-xs-page {
  padding-top: 40px;
}

.app-ed-page-content {
  max-width: $app-page-content-xxl-max-width;
  width: $app-page-content-xxl-max-width;
}

.app-ed-footer {
  border-top: 2px dotted $color-ed-lt-gray;
  // height: $footer-height;
  .app-ed-footer-container {
    max-width: 90vw;
    width: 768px;
    .app-ed-footer-logo {
      img {
        width: 56px;
        height: 35px;
      }
    }
  }
}

.app-ed-footer,
.content-false {
  min-height: $footer-height;
  .app-ed-footer-container {
    max-width: 768px;
    .app-ed-footer-logo {
      img {
        width: 56px;
        height: 35px;
      }
    }
  }
}

// This container is only for static pages
// .container	100%	540px	720px	960px	1140px	1320px
.app-ed-page-container {
  max-width: $app-page-container-max-width;
  width: 100%;
  margin: 0 auto;
  padding: $app-page-container-padding-x $app-page-container-padding-y;
}

@media all and (min-width: 320px) and (max-width: 639.9px) {
  .app-ed-page-container {
    max-width: $app-page-container-xs-max-width;
  }
}
@media all and (min-width: 640px) and (max-width: 767.9px) {
  .app-ed-page-container {
    max-width: $app-page-container-sm-max-width;
  }
}
@media all and (min-width: 768px) and (max-width: 1023.9px) {
  .app-ed-page-container {
    max-width: $app-page-container-md-max-width;
  }
}
@media all and (min-width: 1024px) and (max-width: 1365.9px) {
  .app-ed-page-container {
    max-width: $app-page-container-lg-max-width;
  }
}
@media all and (min-width: 1366px) and (max-width: 1535.9px)  {
  .app-ed-page-container {
    max-width: $app-page-container-xl-max-width;
  }
}

@media all and (min-width: 1536px) {
  .app-ed-page-container {
    max-width: $app-page-container-max-width;
  }
}

body {
  overflow: hidden;
}

// Layout Style
.bg-ed-bubbles {
  background-image: url('/assets/images/background/bubbles.svg');
  background-repeat: no-repeat;
  background-size: 1534px 632px;
  background-attachment: scroll;
  background-position: center;
}

.bg-ed-bubbles-2 {
  background-image: url('/assets/images/background/bubbles-2.svg');
  background-repeat: no-repeat;
  background-size: 1392px 924px;
  background-attachment: fixed;
  // background-position: top;
  background-position: center;
}

.bg-ed-bubbles-3 {
  background-image: url('/assets/images/background/bubble2.svg');
  background-repeat: no-repeat;
  background-size: 1965px 1080px;
  // background-position: top;
  /* background-attachment: fixed; */
  background-position: top;
}
.bg-ed-bottom-bubbles {
  background-image: url('/assets/images/background/bottom-bubbles.svg');
  background-repeat: no-repeat;
  background-size: 1965px 1080px;
  background-position: bottom;
}

.bg-ed-bubbles-4 {
  background-image: url('/assets/images/background/bubble3.svg');
  background-repeat: no-repeat;
  background-size: 1920px 1080px;
  background-attachment: fixed;
  // background-position: top;
  background-position: center;
}

// .bg-ed-partner {
//   background-image: url('/assets/images/background/partner.svg');
//   background-repeat: no-repeat;
//   background-size: 1392px 924px;
//   background-attachment: fixed;
//   // background-position: top;
//   background-position: center;
// }

.bg-ed-partner {
  background-image: url(../../../assets/images/background/partner.svg);
  background-repeat: no-repeat;
}
.bg-ed-partner-detail {
  background-image: url(../../../assets/images/background/partnerdetailsbg.svg);
  background-repeat: no-repeat;
}
.bg-ed-partner-selection {
  background-image: url(../../../assets/images/background/partner-selection.png);
  background-repeat: no-repeat;
}

.bg-ed-voucher:before {
  // width: 16px;
  // content: ' ';
  // background-image: url(../../../assets/images/background/voucher-bubbles.svg);
  // position: absolute;
  // left: -16px;
  // top: -6px;
  // height: 20px;
  position: absolute;
  content: '';
  background-image: url(../../../assets/images/background/voucher-bubbles.svg);
  background-repeat: no-repeat;
  top: 50px;
  left: -287.87109375px;
  width: 641px;
  height: 790px;
  z-index: 0;
}

.bg-shade:before {
  width: 3000px;
  height: 270px;
  position: absolute;
  left: -2600px;
  top: -50px;
  content: '';
  background: #b0effe;
  border-radius: 0 100px 100px 0;
}

.bg-ed-partner-detail-circle:before {
  content: '';
  display: inline-block;
  width: 300px;
  height: 300px;
  -moz-border-radius: 150px;
  -webkit-border-radius: 150px;
  border-radius: 150px;
  background-color: $color-ed-lt-violet;
  position: absolute;
  bottom: -85px;
  left: -120px;
  z-index: 0;
}

.bg-ed-partner-detail-circle:after {
  content: '';
  display: inline-block;
  width: 500px;
  height: 500px;
  -moz-border-radius: 250px;
  -webkit-border-radius: 250px;
  border-radius: 250px;
  background-color: $color-ed-lt-cobald;
  position: absolute;
  bottom: 40px;
  right: -285px;
  z-index: 0;
}

.bg-ed-my-account:before {
  position: absolute;
  content: '';
  background-image: url(../../../assets/images/background/my-account-top-bubbles.svg);
  background-repeat: no-repeat;
  top: -50px;
  right: -20px;
  width: 640px;
  height: 790px;
  z-index: 0;
}

.bg-ed-my-account:after {
  position: absolute;
  content: '';
  background-image: url(../../../assets/images/background/my-account-bottom-bubbles.svg);
  background-repeat: no-repeat;
  bottom: 250px;
  left: 250px;
  width: 410px;
  height: 349px;
  z-index: 0;
}

// .bg-ed-voucher:before {
//   width: 3000px;
//   height: 270px;
//   position: absolute;
//   left: -2600px;
//   top: -50px;
//   content: '';

//   background-image: url(../../../assets/images/background/voucher-bubbles.svg);
//   background-repeat: no-repeat;
// }

// @media all and (min-width: 320px) and (max-width: 639px) {}
// @media all and (min-width: 640px) and (max-width: 767px) {}
// @media all and (min-width: 768px) and (max-width: 1023px) {}
// @media all and (min-width: 1024px) and (max-width: 1365px) {}
// @media all and (min-width: 1366px) and (max-width: 1919px) {}

.app-ed-header {
  // border-bottom: 2px dotted $color-ed-lt-gray;
  // padding-bottom: 15px;
  // padding-top: 15px;
  position: relative;
  overflow: hidden;
}

// .app-ed-header:before {
//   position: absolute;
//   bottom: -10px;
//   left: -10px;
//   height: 20px;
//   width: 20px;
//   background: $color-ed-dk-gray;
//   content: '';
//   border-radius: 10px;
//   z-index: 10;
// }

// .app-ed-header:after {
//   position: absolute;
//   bottom: -10px;
//   left: -10px;
//   height: 20px;
//   width: 20px;
//   background: $color-ed-dk-gray;
//   content: '';
//   border-radius: 10px;
//   z-index: 10;
// }

// .app-ed-header:after {
//   right: -10px;
//   left: auto;
// }

main.app-ed-content {
  // border-top: 2px dotted $color-ed-lt-gray;
  // padding-bottom: 15px;
  // padding-top: 15px;
  position: relative;
  overflow: hidden;
}

.circle:before {
  position: absolute;
  top: 110px;
  left: -10px;
  height: 20px;
  width: 20px;
  background: $color-ed-dk-gray;
  content: '';
  border-radius: 10px;
  z-index: 10;
}

.circle:after {
  position: absolute;
  top: 110px;
  left: -10px;
  height: 20px;
  width: 20px;
  background: $color-ed-dk-gray;
  content: '';
  border-radius: 10px;
  z-index: 10;
}

.circle:after {
  right: -10px;
  left: auto;
}

main.app-ed-content > div:nth-child(2) {
  // border-bottom: 2px dotted $color-ed-lt-gray;
  // padding-bottom: 15px;
  // padding-top: 15px;
  position: relative;
  overflow: hidden;
}

// main.app-ed-content > div:first-child:before,
// main.app-ed-content > div:first-child:after {
//   position: absolute;
//   bottom: -10px;
//   left: -10px;
//   height: 20px;
//   width: 20px;
//   background: $color-ed-dk-gray;
//   content: '';
//   border-radius: 10px;
//   z-index: 10;
//   overflow: hidden;
// }

// main.app-ed-content > div:first-child:after {
//   right: -10px;
//   left: auto;
// }

.app-ed-footer {
  z-index: 0;
  position: relative;
}

.app-ed-footer > div:first-child:before {
  position: absolute;
  top: -10px;
  left: -10px;
  height: 20px;
  width: 20px;
  background: $color-ed-dk-gray;
  content: '';
  border-radius: 10px;
  z-index: 10;
  overflow: hidden;
}

.app-ed-footer > div:first-child:after {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  background: $color-ed-dk-gray;
  content: '';
  border-radius: 10px;
  z-index: 10;
  overflow: hidden;
}

.app-ed-footer > :after {
  right: -10px;
  left: auto;
}

// .app-ed-footer > div::nth-child(2):before,
// .app-ed-footer > div::nth-child(2):after {
//   position: absolute;
//   top: -10px;
//   right: -10px;
//   height: 20px;
//   width: 20px;
//   background: $color-ed-dk-gray;
//   content: '';
//   border-radius: 10px;
//   z-index: 10;
//   overflow: hidden;
// }

// .app-ed-footer > div::nth-child(2):after {
//   left: -10px;
//   right: auto;
// }

.app-ed-cta-page {
  position: absolute;
  top: 3rem;
  right: 0;
  .card {
    margin-top: 10px;
    border-radius: 15px 0 0 15px;
    box-shadow: 0px 3px 6px #00000029;
  }
}

.app-ed-cta-mobile-page {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  .card {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    margin-right: 15px;
    border-radius: 0 0 15px 15px;
    box-shadow: 0px 3px 6px #00000029;
  }
}

@media all and (min-width: 320px) and (max-width: 1023px) {
  .app-ed-header {
    .app-ed-header-nav {
      .app-ed-nav-list {
        .app-ed-nav-menu {
          .app-ed-nav-link {
            height: 40px;
            margin: 10px 0;
            border-radius: 40px;
            .app-ed-nav-icon {
              padding: 10px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
@media all and (min-width: 320px) and (max-width: 639px) {
  .mobile-footer {
    padding: 0 15px;
  }
  .bg-ed-bubbles {
    background-size: 750px 332px;
  }

  .bg-ed-bubbles-2 {
    background-size: 750px 332px;
  }

  .bg-ed-partner {
    background-position-x: -10rem;
    background-position-y: 0.5rem;
    background-size: 100rem;
  }

  .bg-ed-partner-selection {
    background-position-y: 1rem;
    background-position-x: 0rem;
    background-size: 50rem;
  }

  .bg-ed-partner-detail {
    // background-size: 0;
    background-position-x: -27rem;
    background-position-y: -9.5rem;
    background-size: 110rem;
  }

  .app-ed-page-content {
    // max-width: $app-page-content-xs-max-width;
    // width: $app-page-content-xs-max-width;
    max-width: 90vw;
    width: 90vw;
  }
  .app-ed-page {
    z-index: 0;
  }

  .app-ed-page .app-ed-page-content {
    padding: 55px 0;
  }
  .app-ed-header {
    min-height: 85px;
    .app-ed-page-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .app-ed-footer .app-ed-footer-container,
  .content-false .app-ed-footer-container {
    width: 90vw;
  }
  .content-false {
    height: 60px;
  }

  .app-ed-footer {
    // height: 65px;
    min-height: 65px;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .app-ed-nav-link {
    // height: 40px;
    margin: 10px 0;
    // padding: 0 33px;
    .app-ed-nav-icon {
      padding: 8px;
      border-radius: 50%;
      margin: 0;
    }
  }

  .grecaptcha-badge {
    bottom: 110px !important;
  }

  .circle:before {
    position: absolute;
    top: 77px;
    left: -10px;
    height: 20px;
    width: 20px;
    background: $color-ed-dk-gray;
    content: '';
    border-radius: 10px;
    z-index: 10;
  }

  .circle:after {
    position: absolute;
    top: 77px;
    left: -10px;
    height: 20px;
    width: 20px;
    background: $color-ed-dk-gray;
    content: '';
    border-radius: 10px;
    z-index: 10;
  }

  .circle:after {
    right: -10px;
    left: auto;
  }

  .bg-ed-voucher:before {
    width: 0;
    height: 0;
  }
}

@media all and (min-width: 360px) and (max-width: 640px) {
  .bg-ed-partner-detail-circle:before {
    width: 620px;
    height: 620px;
    -moz-border-radius: 310px;
    -webkit-border-radius: 310px;
    border-radius: 310px;
    bottom: -518px;
    left: -357px;
  }

  .bg-ed-partner-detail-circle:after {
    width: 380px;
    height: 380px;
    -moz-border-radius: 200px;
    -webkit-border-radius: 200px;
    border-radius: 200px;
    bottom: -140px;
    right: -320px;
  }

  .bg-ed-my-account:before {
    width: 0;
    height: 0;
  }

  .bg-ed-my-account:after {
    width: 0;
    height: 0;
  }
}

@media all and (min-width: 640px) and (max-width: 767px) {
  .bg-ed-bubbles {
    background-size: 1004px 517px;
  }

  .bg-ed-bubbles-2 {
    background-size: 702px 833px;
  }

  .bg-ed-partner {
    background-position-x: -10rem;
    background-position-y: -3rem;
    background-size: 107rem;
    &.details {
      background-position-x: -25rem;
      background-position-y: -0.5rem;
      background-size: 110rem;
    }
  }

  .bg-ed-partner-detail {
    background-position-x: -28rem;
    background-position-y: -5.5rem;
    background-size: 105rem;
  }

  .bg-ed-partner-selection {
    background-position-y: 1rem;
    background-position-x: 0rem;
    background-size: 50rem;
  }

  .bg-ed-partner-detail-circle:before {
    width: 620px;
    height: 620px;
    -moz-border-radius: 310px;
    -webkit-border-radius: 310px;
    border-radius: 310px;
    bottom: -280px;
    left: -386px;
  }

  .bg-ed-partner-detail-circle:after {
    width: 380px;
    height: 380px;
    -moz-border-radius: 200px;
    -webkit-border-radius: 200px;
    border-radius: 200px;
    bottom: 150px;
    right: -320px;
  }

  .bg-ed-voucher:before {
    background-size: 373px;
    left: -230px;
  }

  .bg-ed-my-account:before {
    background-size: 203px;
    top: -72px;
    right: -420px;
    width: 640px;
    height: 790px;
  }

  .bg-ed-my-account:after {
    background-size: 300px;
    bottom: -653px;
    left: -67px;
    width: 640px;
    height: 790px;
  }

  .app-ed-page-content {
    max-width: $app-page-content-sm-max-width;
    width: $app-page-content-sm-max-width;
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .bg-ed-bubbles {
    background-size: 1177px 485px;
  }
  .bg-ed-bubbles-2 {
    background-size: 746px 676px;
  }

  .bg-ed-partner {
    background-position-x: -25rem;
    background-position-y: -3rem;
    background-size: 112rem;
    &.details {
      background-position-x: -16rem;
      background-position-y: 5.5rem;
      background-size: 82rem;
    }
  }

  .bg-ed-partner-detail {
    background-position: -8rem -9rem;
    background-size: 1517px 1286px;
  }

  .bg-ed-partner-selection {
    background-position-y: 1rem;
    background-position-x: 0rem;
    background-size: 50rem;
  }

  .bg-ed-voucher:before {
    background-size: 520px;
  }

  .bg-ed-partner-detail-circle:before {
    width: 500px;
    height: 500px;
    -moz-border-radius: 250px;
    -webkit-border-radius: 250px;
    border-radius: 250px;
    bottom: -280px;
    left: -270px;
  }

  .bg-ed-partner-detail-circle:after {
    width: 380px;
    height: 380px;
    -moz-border-radius: 200px;
    -webkit-border-radius: 200px;
    border-radius: 200px;
    bottom: 150px;
    right: -300px;
  }

  .bg-ed-my-account:before {
    background-size: 250px;
    top: -100px;
    right: -300px;
    width: 640px;
    height: 790px;
  }

  .bg-ed-my-account:after {
    background-size: 200px;
    bottom: 100px;
    left: 35px;
    width: 200px;
    height: 200px;
  }
  // .app-ed-page-content {
  //   max-width: $app-page-content-md-max-width;
  //   width: $app-page-content-md-max-width;
  // }

  .app-ed-header .app-ed-header-nav .app-ed-nav-list .app-ed-nav-menu .app-ed-nav-link {
    height: 40px;
    margin: 10px 0;
    border-radius: 40px;
  }
}
@media all and (max-width: 991px) {
  .app-ed-nav-label {
    display: none !important;
  }
}

@media all and (min-width: 768px) and (max-width: 820px) {
  .app-ed-page-content {
    max-width: $app-page-content-md-max-width;
    width: $app-page-content-md-max-width;
  }
}

@media all and (min-width: 820px) and (max-width: 991px) {
  .app-ed-page-content {
    max-width: 800px;
    width: 800px;
  }
}

@media all and (min-width: 992px) and (max-width: 1023px) {
  .app-ed-page-content {
    max-width: 950px;
    width: 950px;
  }
}

@media all and (min-width: 1024px) and (max-width: 1365px) {
  .bg-ed-bubbles {
    background-size: 1177px 485px;
  }
  .bg-ed-bubbles-2 {
    background-size: 1021px 730px;
  }

  .bg-ed-bubbles-3 {
    background-size: 0;
  }

  .bg-ed-partner {
    background-position-x: -20rem;
    background-position-y: -3rem;
    background-size: 112rem;
  }
  .bg-ed-partner-detail {
    // background-position: -86px -6rem;
    // background-size: 1456px 1176px;
    background-position: -8rem -9rem;
    background-size: 1517px 1286px;
  }

  .bg-ed-partner-selection {
    background-position-x: -5rem;
    background-position-y: -1rem;
  }

  .bg-ed-partner-detail-circle:before {
    width: 500px;
    height: 500px;
    -moz-border-radius: 250px;
    -webkit-border-radius: 250px;
    border-radius: 250px;
    bottom: -280px;
    left: -270px;
  }

  .bg-ed-partner-detail-circle:after {
    width: 380px;
    height: 380px;
    -moz-border-radius: 200px;
    -webkit-border-radius: 200px;
    border-radius: 200px;
    bottom: 150px;
    right: -300px;
  }

  .bg-ed-my-account:before {
    background-size: 250px;
    top: -100px;
    right: -300px;
    width: 640px;
    height: 790px;
  }

  .bg-ed-my-account:after {
    background-size: 300px;
    bottom: -390px;
    left: 35px;
    width: 640px;
    height: 790px;
  }

  .bg-ed-voucher:before {
    background-size: 500px;
  }

  .app-ed-page-content {
    max-width: $app-page-content-lg-max-width;
    width: $app-page-content-lg-max-width;
  }
}

@media all and (min-width: 1366px) and (max-width: 1919px) {
  .bg-ed-bubbles {
    background-size: 1177px 485px;
  }
  .bg-ed-bubbles-2 {
    background-size: 1240px 694px;
  }

  .bg-ed-bubbles-3 {
    background-size: 1750px ​1046px;
  }

  .bg-ed-partner {
    background-position-x: -10rem;
    background-position-y: -3rem;
    background-size: 112rem;
  }

  .bg-ed-partner-selection {
    background-position-y: -1rem;
  }
  .bg-ed-partner-detail {
    background-position: 0rem -6rem;
    background-size: 1917px 1286px;
    & .partner-detail-page {
      background-position-x: -9rem;
      background-position-y: -1rem;
      background-size: 112rem;
    }
  }

  .bg-ed-partner-detail-circle:before {
    width: 500px;
    height: 500px;
    -moz-border-radius: 250px;
    -webkit-border-radius: 250px;
    border-radius: 250px;
    bottom: -280px;
    left: -270px;
  }

  .bg-ed-partner-detail-circle:after {
    width: 500px;
    height: 500px;
    -moz-border-radius: 250px;
    -webkit-border-radius: 250px;
    border-radius: 250px;
    bottom: 40px;
    right: -335px;
  }

  .bg-ed-my-account:before {
    top: -50px;
    right: -155px;
    width: 640px;
    height: 790px;
  }

  .bg-ed-my-account:after {
    bottom: -320px;
    left: 50px;
    width: 530px;
    height: 690px;
    background-size: 260px;
  }

  .app-ed-page-content {
    max-width: $app-page-content-xl-max-width;
    width: $app-page-content-xl-max-width;
  }
}
@media all and (min-width: 1920px) {
  .bg-ed-partner {
    // background-position-x: -15rem;
    background-position-y: 2rem;
    background-size: 95vw 95vh;
  }
  // .partner-detail-page .bg-ed-partner {
  //   // background-position-x: -9rem;
  //   // background-position-y: -1rem;
  //   // background-size: 112rem;
  //   background-position-y: -1rem;
  //   background-size: 105vw 132vh;
  // }
  .bg-ed-partner-detail {
    background-position-y: -8rem;
    background-size: 98vw 160vh;
  }
  .bg-ed-partner-selection {
    background-position-y: -1rem;
  }
}
