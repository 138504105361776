@import '../../../theme/common/variables.scss';

.modal {
  background-color: rgb(109 119 135 / 50%); // $modal-bg-color;
  // height: auto;
  width: 100%;
  height: 100%;
}

.modal-dark {
  background-color: $modal-bg-dark-color;
}

.modal-header {
  .modal-close {
    position: absolute;
    right: -15px;
    top: -15px;
  }
  .modal-close-btn {
    background: $color-ed-red;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    img {
      width: 41px;
      height: 41px;
    }
  }
}
