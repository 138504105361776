// tailwind.scss
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-above-center {
  display: flex;
  align-items: inherit;
  justify-content: center;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-start-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row-above-center {
  display: flex;
  flex-direction: row;
  align-items: inherit;
  justify-content: center;
}

.flex-row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-space-around {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.flex-row-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-col-above-center {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: center;
}

.flex-col-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-col-space-around {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.flex-col-space-between {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.h-fit-content {
  height: fit-content;
}
