@import '../../../../theme/common/variables.scss';

.modal {
  .app-ed-content {
    min-height: 100%;
  }
}
.mobile-modal-close {
  height: 85px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  .mobile-modal-close-btn {
    background: $color-ed-red;
    height: 40px;
    border-radius: 40px 0 0 40px;
    width: 50px;
    padding-right: 10px;
    padding-top: 4px;
  }
}

.partner-mobile-modal {
  height: calc(100% - 65px);
}
.partner-detail-confirm-mobile-modal {
  height: 100%;
}
