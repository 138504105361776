.default-btn-loader {
  position: absolute;
  height: 30px;
  width: 30px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #555;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.outline-btn-loader {
  position: absolute;
  height: 30px;
  width: 30px;
  border: 4px solid #555;
  border-top: 4px solid #f3f3f3;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
