.detailed-voucher {
  .voucher {
    width: 550px;
    height: 225px;
  }
}

.voucher-info {
  border-top: 2px dotted #6d7787;
}
.information {
  width: 537px;
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .detailed-voucher {
    .voucher {
      width: 100%;
      height: 204px;
    }
  }

  .information {
    width: 100%;
  }
}
@media all and (min-width: 640px) and (max-width: 767px) {
  .detailed-voucher {
    .voucher {
      width: 360px;
      height: 204px;
    }
  }

  .information {
    width: 100%;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .detailed-voucher {
    .voucher {
      width: 370px;
      height: 204px;
    }
  }

  .information {
    width: 537px;
  }
}
@media all and (min-width: 1024px) and (max-width: 1365px) {
  .detailed-voucher {
    .voucher {
      width: 450px;
      height: 204px;
    }
  }

  .information {
    width: 451px;
  }
}
@media all and (min-width: 1366px) and (max-width: 1919px) {
  .detailed-voucher {
    .voucher {
      width: 450px;
      height: 204px;
    }
  }

  .information {
    width: 537px;
  }
}
