.voucher {
  .partner-main-voucher {
    padding-top: 15px;
    padding-bottom: 15px;
    .voucher-content {
      // margin-top: -30px;
      // margin-bottom: -15px;
    }
    .voucher-header {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      padding: 0 15px;
      z-index: 2;
      position: relative;
    }
    .voucher-detail {
      z-index: 0;
      position: relative;
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .voucher {
    .partner-main-voucher {
      .voucher-header {
        padding: 0 10px;
      }
    }
  }
}
@media all and (min-width: 640px) and (max-width: 767px) {
  .voucher {
    .partner-main-voucher {
      .voucher-header {
        padding: 0 15px;
      }
    }
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .voucher {
    .partner-main-voucher {
      .voucher-header {
        padding: 0 15px;
      }
    }
  }
}
@media all and (min-width: 1024px) and (max-width: 1365px) {
}
@media all and (min-width: 1366px) and (max-width: 1919px) {
}
