@import '../../../../theme/common/theme-color.scss';

.price-voucher {
  width: 350px;
}
.partner-list-voucher {
  height: 66px;
  img {
    height: 35px;
  }
}
.voucher-date {
  border-right: 2px dotted $color-ed-lt-gray;
  margin: 5px 0;
  height: 97%;
}
.part-voucher-list {
  // width: 100vw;
  .voucher-list-item {
    display: flex;
    .voucher-date {
      // width: 65px;
      height: auto;
      display: flex;
      padding-right: 10px;
      margin: 0;
      width: 50px;
      .date-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .voucher-list-item {
      width: calc(100% - 65px);
      padding-left: 10px;
      .voucher-trigger-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .partner-list-voucher {
    height: 75px;
  }

  .part-voucher-list {
    width: 98vw;
    .voucher-list-item {
      display: flex;
      .voucher-date {
        // width: 65px;
        height: auto;
        display: flex;
        padding-right: 10px;
        min-width: 40px;
        margin: 0;
        width: auto;
        .date-label {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .voucher-list-item {
        width: calc(100% - 65px);
        padding-left: 10px;
        .voucher-trigger-item {
          display: flex;
          flex-direction: column-reverse;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;
          div:first-child {
            // padding-top: 10px;
            // padding-bottom: 10px;
          }
        }
      }
    }
  }
}

// @media all and (min-width: 320px) and (max-width: 475px) {

//   .voucher-list {
//     width: 100%;
//     display: flex;
//     .voucher-date {
//       width: 45px;
//       height: auto;
//       .date-label {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//       }
//     }
//     .voucher-list-item {
//       width: calc(100% - 45px);
//       padding-left: 10px;
//     }
//   }
// }
