@import '../../../theme/common/theme-color.scss';

.selected-voucher {
  .voucher-action {
    width: 445px;
  }
  .voucher {
    width: 445px;
    height: 231px;
    img {
      max-height: 50px;
      width: auto;
    }
  }
}

.available-voucher {
  .voucher {
    width: 255px;
    height: 231px;
    img {
      max-height: 33px;
      width: auto;
    }
  }
}

.voucher-list > img {
  width: 63px;
  height: 63px;
}

// Filter Modal
.voucher-filter-modal {
  .modal-content {
    width: 540px;
  }
  .modal-card {
    // height: 660px;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-header {
      height: 58px;
      position: relative;
      border-bottom: 1px solid $color-ed-gray;
      .modal-close {
        position: absolute;
        right: 35px;
        top: 12px;
      }
      .modal-close-btn {
        background: $color-ed-red;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }
    .filter-content {
      width: 100%;
      &:not(:last-child) {
        border-bottom: 1px solid $color-ed-gray;
      }
      .view-selector,
      .voucher-selector {
        width: 350px;
        padding-top: 14px;

        div.btn-selection {
          padding: 15px 0;
          .grid.grid-cols-2 {
            column-gap: 30px;
          }
          button {
            width: 160px;
            &:disabled,
            &[disabled] {
              border: 1px solid $color-ed-lt-gray;
              // background-color: $color-ed-lt-gray;
              color: $color-ed-lt-gray;
            }
          }
        }
        div.list-selection,
        .apply-filter {
          padding: 15px 0;

          .grid.grid-cols-2 {
            column-gap: 30px;
            .fil-list {
              border-bottom: 1px solid $color-ed-gray;
              .fil-list-item {
                padding: 10px 0 10px 10px;
              }
            }
          }
        }
        .apply-sort {
          padding: 15px 0;
        }
      }
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .voucher-filter-modal {
    .modal-content {
      width: 95vw;
      padding: 10px 15px;
    }
  }
  .voucher-filter-modal .modal-card .filter-content .view-selector div.btn-selection button,
  .voucher-filter-modal .modal-card .filter-content .voucher-selector div.btn-selection button {
    width: 100%;
    margin: 10px 0;
  }
  .voucher-filter-modal .modal-card .modal-header .modal-close {
    right: 12px;
    top: 12px;
  }
  .selected-voucher {
    .voucher-action {
      width: 100%;
    }
    .voucher {
      width: 100%;
      height: 135px;
      img {
        max-height: 20px;
        width: auto;
      }
    }
  }

  .available-voucher {
    .voucher {
      width: 100%;
      height: 135px;
      img {
        max-height: 15px;
        width: auto;
      }
    }
  }
}
@media all and (min-width: 640px) and (max-width: 767px) {
  .selected-voucher {
    .voucher-action {
      width: 243px;
    }
    .voucher {
      width: 243px;
      height: 135px;
      img {
        max-height: 25px;
        width: auto;
      }
    }
  }

  .available-voucher {
    .voucher {
      width: 150px;
      height: 135px;
      img {
        max-height: 15px;
        width: auto;
      }
    }
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .selected-voucher {
    .voucher-action {
      width: 270px;
    }
    .voucher {
      width: 270px;
      height: 140px;
      img {
        max-height: 25px;
        width: auto;
      }
    }
  }

  .available-voucher {
    .voucher {
      width: 165px;
      height: 135px;
      img {
        max-height: 15px;
        width: auto;
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 820px) {
  .selected-voucher {
    .voucher-action {
      width: 270px;
    }
    .voucher {
      width: 270px;
      height: 150px;
      img {
        max-height: 25px;
        width: auto;
      }
    }
  }

  .available-voucher {
    .voucher {
      width: 165px;
      height: 135px;
      img {
        max-height: 15px;
        width: auto;
      }
    }
  }
}

@media all and (min-width: 820px) and (max-width: 991px) {
  .selected-voucher {
    .voucher-action {
      width: 320px;
    }
    .voucher {
      width: 320px;
      height: 170px;
      img {
        max-height: 25px;
        width: auto;
      }
    }
  }

  .available-voucher {
    .voucher {
      width: 165px;
      height: 135px;
      img {
        max-height: 15px;
        width: auto;
      }
    }
  }
}

@media all and (min-width: 992px) and (max-width: 1023px) {
  .selected-voucher {
    .voucher-action {
      width: 350px;
    }
    .voucher {
      width: 350px;
      height: 180px;
      img {
        max-height: 25px;
        width: auto;
      }
    }
  }

  .available-voucher {
    .voucher {
      width: 165px;
      height: 135px;
      img {
        max-height: 15px;
        width: auto;
      }
    }
  }
}

@media all and (min-width: 1024px) and (max-width: 1365px) {
  .selected-voucher {
    .voucher-action {
      width: 370px;
    }
    .voucher {
      width: 370px;
      height: 192px;
      img {
        max-height: 35px;
        width: auto;
      }
    }
  }

  .available-voucher {
    .voucher {
      width: 210px;
      height: 191px;
      img {
        max-height: 20px;
        width: auto;
      }
    }
  }
}
@media all and (min-width: 1366px) and (max-width: 1919px) {
  .selected-voucher {
    .voucher-action {
      width: 444px;
    }
    .voucher {
      width: 444px;
      height: 231px;
      img {
        max-height: 42px;
        width: auto;
      }
    }
  }

  .available-voucher {
    .voucher {
      width: 255px;
      height: 231px;
      img {
        max-height: 33px;
        width: auto;
      }
    }
  }
}
