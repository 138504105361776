.sign-in-ed-verification-list {
  padding: 15px 20px;
}
.sign-in-terms-condition {
  padding: 15px 0;
}
.sign-in-ed {
  .sign-in-ed-card {
    margin-top: 35px;
    margin-bottom: 95px;
  }
}

@media all and (min-width: 1366px) {
  .sign-in-ed {
    .sign-in-ed-card {
      width: 540px;
      padding: 25px 95px;
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .sign-in-ed {
    .sign-in-ed-card {
      // width: 85vw;
      padding: 15px;
      margin-bottom: 50px;
    }
    .sign-in-terms-condition {
      padding: 15px 5px;
    }
  }
}

@media all and (min-width: 640px) and (max-width: 767px) {
  .sign-in-ed {
    .sign-in-ed-card {
      width: 400px;
      padding: 25px;
      margin-bottom: 50px;
    }
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .sign-in-ed {
    .sign-in-ed-card {
      width: 450px;
      padding: 25px 50px;
    }
  }
}
@media all and (min-width: 1024px) and (max-width: 1365px) {
  .sign-in-ed {
    .sign-in-ed-card {
      width: 540px;
      padding: 25px 50px;
    }
  }
}
