@import '../../../theme/common/theme-color.scss';

section.partner-overview {
  // width: 350px;
}
section.partner-list {
  // width: 635px;
  .grid {
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 50px;
    column-gap: 50px;
  }
}

.card-content {
  padding: 30px 20px;
}

.selected-default-partner {
  img {
    max-height: 100px;
    width: 100%;
  }
}

// Filter Modal
.partner-filter-modal {
  .modal-content {
    width: 540px;
  }
  .modal-card {
    // height: 660px;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-header {
      height: 58px;
      position: relative;
      border-bottom: 1px solid $color-ed-gray;
      .modal-close {
        position: absolute;
        right: 35px;
        top: 12px;
      }
      .modal-close-btn {
        background: $color-ed-red;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }
    .filter-content {
      width: 100%;
      &:not(:last-child) {
        border-bottom: 1px solid $color-ed-lt-gray;
      }
      .view-selector,
      .categories-selector,
      .sort-selector {
        width: 350px;
        padding-top: 14px;

        div.btn-selection {
          padding: 15px 0;
          .grid.grid-cols-2 {
            column-gap: 30px;
          }
          button {
            width: 160px;
            &:disabled,
            &[disabled] {
              // border: 1px solid $color-ed-lt-gray;
              // background-color: $color-ed-lt-gray;
              // color: $color-ed-lt-gray;
              // Active
              color: $color-ed-blue;
            }
          }
        }
        div.list-selection,
        .apply-filter {
          padding-bottom: 15px;

          .grid.grid-cols-2 {
            column-gap: 30px;
            .fil-list {
              border-bottom: 1px solid $color-ed-lt-gray;
              .fil-list-item {
                padding: 10px 0 10px 10px;
              }
            }
          }
          .grid.grid-cols-1 {
            column-gap: 30px;
            .fil-list {
              border-bottom: 1px solid $color-ed-lt-gray;
              .fil-list-item {
                padding: 10px 0 10px 10px;
              }
            }
          }
        }
        .apply-sort {
          padding: 15px 0;
        }
      }
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  section.partner-overview {
    width: 100%;
  }
  section.partner-list {
    width: 100%;
    .grid {
      row-gap: 10px;
      column-gap: 10px;
    }
  }
  .partner-filter-modal {
    .modal {
      width: 100%;
    }
    .modal-content {
      width: 90vw;
      padding: 0 10px;
    }
  }

  .partner-filter-modal .modal-card .filter-content .view-selector div.btn-selection button,
  .partner-filter-modal .modal-card .filter-content .categories-selector div.btn-selection button,
  .partner-filter-modal .modal-card .filter-content .sort-selector div.btn-selection button {
    width: 100%;
  }

  .card-content {
    padding: 20px;
  }
  .partner-filter-modal .modal-card .modal-header .modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
@media all and (min-width: 640px) and (max-width: 767px) {
  // section.partner-overview {
  //   width: 440px;
  // }
  section.partner-list {
    // width: 450px;
    .grid {
      row-gap: 12px;
      column-gap: 13px;
    }
  }

  .partner-filter-modal > .modal-content {
    width: 395px;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  // section.partner-overview {
  //   width: 270px;
  // }
  // section.partner-list {
  //   width: 330px;
  //   .grid {
  //     row-gap: 27px;
  //     column-gap: 29px;
  //   }
  // }
}

@media all and (min-width: 768px) and (max-width: 820px) {
  section.partner-list {
    // width: 330px;
    .grid {
      row-gap: 15px;
      column-gap: 25px;
    }
  }
}

@media all and (min-width: 820px) and (max-width: 991px) {
  section.partner-list {
    // width: 470px;
    .grid {
      row-gap: 17px;
      column-gap: 25px;
    }
  }
}

@media all and (min-width: 992px) and (max-width: 1023px) {
  section.partner-list {
    // width: 530px;
    .grid {
      row-gap: 22px;
      column-gap: 25px;
    }
  }
}

@media all and (min-width: 1024px) and (max-width: 1365px) {
  section.partner-overview {
    // width: 290px;
  }
  section.partner-list {
    // width: 530px;
    .grid {
      row-gap: 10px;
      column-gap: 29px;
    }
  }
  .partner-filter-modal {
    .modal-content {
      width: 450px;
    }
  }
}
@media all and (min-width: 1366px) and (max-width: 1079px) {
  section.partner-overview {
    // width: 350px;
  }
  section.partner-list {
    // width: 635px;
    .grid {
      row-gap: 30px;
      column-gap: 77px;
    }
  }
}
