@import '../../../../theme/common/variables.scss';

.app-ed-page {
  .app-ed-page-content {
    padding: 45px 0;
    .page-title {
      padding-bottom: 60px;
    }
    // .partner-detail {
    //   width: 100%;
    //   display: flex;
    //   margin-bottom: 50px;
    .selected-partner {
      max-width: 445px;
      // margin-right: 125px;
      padding-top: 25px;
      .selected-partner-card {
        max-width: 445px;
        height: 230px;
        img {
          max-height: 160px;
        }
      }
    }
    .available-details {
      // width: 330px;
      .selection-btn {
        padding: 30px 15px;
        button {
          // width: 100%;
          padding: 12px 0;
          margin: 15px 0;
        }
        .select-btn > button,
        .overview-btn > button {
          // width: 100%;
          padding: 11px 0;
          margin: 15px 0;

          border: 1px solid $color-ed-dk-gray;
          // background-color: $color-ed-dk-gray;
          color: $color-ed-dk-gray;
          &:disabled,
          &[disabled] {
            border: 1px solid $color-ed-lt-gray;
            // background-color: $color-ed-lt-gray;
            color: $color-ed-lt-gray;
          }
        }
      }
    }
    // }
    .partner-faq {
      width: 920px;
      .faq-list {
        padding: 30px 0;
      }
    }
    .faq-btn > button {
      padding: 12px 52px;
    }
  }
}

// Filter Modal
// .modal-container {
//   width: 540px;
// }
.partner-detail-modal {
  .modal-close {
    position: absolute;
    right: -15px;
    top: -15px;
  }
  .modal-close-btn {
    background: $color-ed-red;
    border-radius: 50%;
    width: 61px;
    height: 61px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    img {
      width: 59px;
      height: 59px;
    }
  }
  .modal-card {
    height: 660px;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
      width: 920px;
      height: 460px;
    }
    .modal-container {
      width: 920px;
      height: 460px;
      padding: 45px 65px 84px 95px;
      .modal-content-title {
        padding-bottom: 25px;
      }
      .partner-logo {
        padding: 20px;
      }
    }
    .partner-detail {
      .partner-logo > .card {
        width: 350px;
        height: 231px;
      }

      .partner-description {
        padding-bottom: 27px;
        p {
          padding-bottom: 10px;
        }
      }
    }
  }
}

.partner-detail-confirm-modal {
  .modal-close {
    position: absolute;
    right: -15px;
    top: -15px;
  }
  .modal-close-btn {
    background: $color-ed-red;
    border-radius: 50%;
    width: 61px;
    height: 61px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    img {
      width: 59px;
      height: 59px;
    }
  }
  .modal-card {
    height: 660px;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
      width: 920px;
      // height: 460px;
    }
    .modal-container {
      padding: 45px;
      .modal-content-title {
        padding-bottom: 25px;
      }
      .partner-logo {
        padding: 20px;
      }
    }
    //   .partner-detail {
    //     .partner-logo > .card {
    //       width: 350px;
    //       height: 231px;
    //     }

    //     .partner-description {
    //       padding-bottom: 27px;
    //       p {
    //         padding-bottom: 10px;
    //       }
    //     }
    //   }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .app-ed-page {
    .app-ed-page-content {
      .page-title {
        padding-bottom: 20px;
      }
      .selected-partner {
        padding: 15px 0;
        width: 100%;
        max-width: 100%;
      }
      .selected-partner-card {
        width: 100%;
        max-width: 100% !important;
      }
      .partner-detail {
        .selected-partner {
          width: 100%;
          margin-right: 63px;
          .selected-partner-card {
            width: 100%;
            height: 166px;
            img {
              max-height: 100px;
            }
          }
        }
      }
      .partner-faq {
        width: 310px;
        .faq-list {
          padding: 25px 0;
        }
      }
    }
  }
  .app-ed-page .app-ed-page-content .selected-partner .selected-partner-card img {
    max-height: 125px;
  }
  .available-details {
    padding-top: 20px;
  }

  .selection-btn {
    padding: 5px;
  }
  .partner-detail-modal {
    .modal-close {
      position: absolute;
      right: -15px;
      top: -15px;
    }
    .modal-close-btn {
      background: $color-ed-red;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      img {
        width: 41px;
        height: 41px;
      }
    }
  }
}
@media all and (min-width: 640px) and (max-width: 767px) {
  .app-ed-page {
    .app-ed-page-content {
      .partner-detail {
        .selected-partner {
          width: 294px;
          margin-right: 63px;
          .selected-partner-card {
            width: 294px;
            height: 166px;
            img {
              max-height: 110px;
            }
          }
        }
      }
      .partner-faq {
        width: 492px;
        .faq-list {
          padding: 25px 0;
        }
      }
    }
  }
  .selection-btn {
    padding: 5px;
  }
  .partner-detail-modal {
    .modal-close {
      position: absolute;
      right: -15px;
      top: -15px;
    }
    .modal-close-btn {
      background: $color-ed-red;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      img {
        width: 41px;
        height: 41px;
      }
    }
    .modal-card {
      .modal-content {
        width: 498px;
        height: 701px;
      }
      .modal-container {
        width: 498px;
        height: 701px;
        padding: 45px 50px 50px 50px;
        .modal-content-title {
          padding-bottom: 0;
        }
      }
      .partner-detail {
        .partner-logo {
          padding-top: 74px;
          padding-bottom: 65px;
          .card {
            width: 396px;
            height: 168px;
          }
        }

        .partner-description {
          padding-bottom: 27px;
          p {
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  .partner-detail-confirm-modal {
    .modal-close {
      position: absolute;
      right: -15px;
      top: -15px;
    }
    .modal-close-btn {
      background: $color-ed-red;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      img {
        width: 41px;
        height: 41px;
      }
    }
    .modal-card {
      .modal-content {
        width: 498px;
        // height: 701px;
      }
      .modal-container {
        width: 498px;
        // height: 701px;
        padding: 45px;
        .modal-content-title {
          padding-bottom: 0;
        }
      }
      .partner-detail {
        .partner-logo {
          padding-top: 74px;
          padding-bottom: 65px;
          .card {
            width: 396px;
            height: 168px;
          }
        }

        .partner-description {
          padding-bottom: 27px;
          p {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .app-ed-page {
    .app-ed-page-content {
      .partner-detail {
        .selected-partner {
          width: 330px;
          margin-right: 90px;
          .selected-partner-card {
            width: 330px;
            height: 181px;
            img {
              max-height: 120px;
            }
          }
        }
      }
      .partner-faq {
        width: 570px;
        .faq-list {
          padding: 25px 0;
        }
      }
    }
  }

  .selection-btn {
    padding: 5px;
  }
  .partner-detail-modal {
    .modal-close {
      position: absolute;
      right: -15px;
      top: -15px;
    }
    .modal-close-btn {
      background: $color-ed-red;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      img {
        width: 41px;
        height: 41px;
      }
    }
    .modal-card {
      .modal-content {
        width: 570px;
        height: 646px;
      }
      .modal-container {
        width: 570px;
        height: 646px;
        padding: 45px 50px 50px 50px;
        .modal-content-title {
          padding-bottom: 0;
        }
      }
      .partner-detail {
        .partner-logo {
          padding-top: 74px;
          padding-bottom: 65px;
          .card {
            width: 450px;
            height: 168px;
          }
        }

        .partner-description {
          padding-bottom: 27px;
          p {
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  .partner-detail-confirm-modal {
    .modal-close {
      position: absolute;
      right: -15px;
      top: -15px;
    }
    .modal-close-btn {
      background: $color-ed-red;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      img {
        width: 41px;
        height: 41px;
      }
    }
    .modal-card {
      .modal-content {
        width: 570px;
        // height: 646px;
      }
      .modal-container {
        width: 570px;
        // height: 646px;
        padding: 45px 50px 50px 50px;
        .modal-content-title {
          padding-bottom: 0;
        }
      }
      .partner-detail {
        .partner-logo {
          padding-top: 74px;
          padding-bottom: 65px;
          .card {
            width: 450px;
            height: 168px;
          }
        }

        .partner-description {
          padding-bottom: 27px;
          p {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

@media all and (min-width: 1024px) and (max-width: 1365px) {
  .app-ed-page {
    .app-ed-page-content {
      .partner-detail {
        .selected-partner {
          width: 450px;
          margin-right: 110px;
          .selected-partner-card {
            width: 450px;
            height: 247px;
            img {
              max-height: 160px;
            }
          }
        }
      }
      .partner-faq {
        width: 770px;
        .faq-list {
          padding: 30px 0;
        }
      }
    }
  }
  .partner-detail-modal {
    .modal-card {
      .modal-content {
        width: 769px;
        height: 427px;
      }
      .modal-container {
        width: 769px;
        height: 427px;
        padding: 40px;
        .modal-content-title {
          padding-bottom: 20px;
        }
        .partner-logo {
          padding: 20px;
        }
      }
      .partner-detail {
        .partner-logo > .card {
          width: 290px;
          height: 180px;
        }

        .partner-description {
          padding-bottom: 27px;
          p {
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  .partner-detail-confirm-modal {
    .modal-card {
      .modal-content {
        width: 769px;
        // height: 427px;
      }
      .modal-container {
        width: 769px;
        // height: 427px;
        padding: 40px;
        .modal-content-title {
          padding-bottom: 20px;
        }
        .partner-logo {
          padding: 20px;
        }
      }
      .partner-detail {
        .partner-logo > .card {
          width: 290px;
          height: 180px;
        }

        .partner-description {
          padding-bottom: 27px;
          p {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
// section.partner-overview {
//   width: 350px;
// }
// section.partner-list {
//   width: 635px;
//   .grid {
//     row-gap: 30px;
//     column-gap: 77px;
//   }
// }

// @media all and (min-width: 320px) and (max-width: 639px) {
//   section.partner-overview {
//     width: 350px;
//   }
//   section.partner-list {
//     width: 100%;
//     .grid {
//       row-gap: 10px;
//       column-gap: 10px;
//     }
//   }
// }
@media all and (max-width: 767px) {
  .selection-btn {
    padding: 0 !important;
  }
  //   section.partner-overview {
  //     width: 396px;
  //   }
  //   section.partner-list {
  //     width: 396px;
  //     .grid {
  //       row-gap: 12px;
  //       column-gap: 13px;
  //     }
  //   }
}
// @media all and (min-width: 768px) and (max-width: 1023px) {
//   section.partner-overview {
//     width: 270px;
//   }
//   section.partner-list {
//     width: 330px;
//     .grid {
//       row-gap: 27px;
//       column-gap: 29px;
//     }
//   }
// }
// @media all and (min-width: 1024px) and (max-width: 1365px) {
//   section.partner-overview {
//     width: 290px;
//   }
//   section.partner-list {
//     width: 530px;
//     .grid {
//       row-gap: 28px;
//       column-gap: 29px;
//     }
//   }
// }
// @media all and (min-width: 1366px) and (max-width: 1079px) {
//   section.partner-overview {
//     width: 350px;
//   }
//   section.partner-list {
//     width: 635px;
//     .grid {
//       row-gap: 30px;
//       column-gap: 77px;
//     }
//   }
// }
