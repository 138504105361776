@import '../../../../theme/common/variables.scss';

.partner-detail-confirm {
  .selected-partner-card {
    width: 350px;
    height: 231px;
    img {
      max-height: 160px;
    }
  }
}
@media all and (min-width: 320px) and (max-width: 639px) {
  .partner-detail-confirm {
    .selected-partner-card {
      width: 300px;
      height: 168px;
      img {
        max-height: 100px;
      }
    }
  }
}
@media all and (min-width: 640px) and (max-width: 767px) {
  .partner-detail-confirm {
    .selected-partner-card {
      width: 350px;
      height: 168px;
      img {
        max-height: 110px;
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .partner-detail-confirm {
    .selected-partner-card {
      width: 450px;
      height: 175px;
      img {
        max-height: 120px;
      }
    }
  }
}

@media all and (min-width: 1024px) and (max-width: 1365px) {
  .partner-detail-confirm {
    .selected-partner-card {
      width: 320px;
      height: 231px;
      img {
        max-height: 160px;
      }
    }
  }
}
