// .partner-block-card {
//   width: 160px;
//   height: 160px;
// }

// @media all and (min-width: 320px) and (max-width: 819px) {
.partner-block-card {
  width: auto;
  height: 150px;
}
// }
// @media all and (min-width: 640px) and (max-width: 767px) {
//   .partner-block-card {
//     width: 192px;
//     height: 192px;
//   }
// }
// @media all and (min-width: 820px) and (max-width: 1023px) {
//   .partner-block-card {
//     width: 150px;
//     height: 150px;
//   }
// }
// @media all and (min-width: 1024px) and (max-width: 1365px) {
//   .partner-block-card {
//     width: 157px;
//     height: 157px;
//   }
// }
// @media all and (min-width: 1366px) {
//   .partner-block-card {
//     width: 160px;
//     height: 160px;
//   }
// }
