.feedback-view {
  padding-top: 28px;
  width: 411px;
  .feedback-img-container > img {
    width: 389px;
    height: 403px;
  }
}

@media all and (min-width: 1366px) and (max-width: 1079px) {
  .feedback-view {
    padding-top: 28px;
    width: 411px;
    .feedback-img-container > img {
      width: 403px;
      height: 403px;
    }
  }
}

@media all and (min-width: 1024px) and (max-width: 1365px) {
  .feedback-view {
    padding-top: 23px;
    width: 330px;
    .feedback-img-container > img {
      width: 300px;
      height: 300px;
    }
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .feedback-view {
    padding-top: 18px;
    width: 330px;
    .feedback-img-container > img {
      width: 300px;
      height: 300px;
    }
  }
}

@media all and (min-width: 640px) and (max-width: 767px) {
  .feedback-view {
    padding-top: 18px;
    width: 330px;
    .feedback-img-container > img {
      width: 300px;
      height: 300px;
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .feedback-view {
    padding-top: 25px;
    padding-bottom: 25px;
    width: auto;
    .feedback-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 85vw;
        height: auto !important;
      }
    }
  }
}
