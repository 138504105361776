/* CHECKBOX TOGGLE SWITCH */
/* @apply rules for documentation, these do not work as inline style */
.toggle-checkbox:checked {
  @apply: right-0 bg-blue-500;
  right: 0;
  border-color: #0e8afd;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #0e8afd;
}

div#switch-toggle {
  padding: 2px;
}

.switch-s {
  width: 2.5rem;
  height: 1rem;
}
.switch-s > div#switch-toggle {
  width: 1.25rem;
  height: 1.25rem;
}
.switch-m {
  width: 2.5rem;
  height: 1rem;
}
.switch-m > div#switch-toggle {
  width: 1.25rem;
  height: 1.25rem;
}
.switch-l {
  width: 3rem;
  height: 1.5rem;
}
.switch-l > div#switch-toggle {
  width: 1.5rem;
  height: 1.5rem;
}
