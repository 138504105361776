// Common App Theme
@import './theme-color.scss';
@import './variables.scss';
@import './utils.scss';
@import './responsive/responsive.scss';
// @import './fonts.scss';

// Main App layout container.
// Everything will be inside of this container.
.app-ed-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

// TODO: Design approved only xxl
// @media (min-width: 576px) {
//   .app-ed-container {
//     max-width: 540px;
//   }
// }

// @media (min-width: 768px) {
//   .app-ed-container {
//     max-width: 720px;
//   }
// }
// @media (min-width: 992px) {
//   .app-ed-container {
//     max-width: 960px;
//   }
// }
// @media (min-width: 1200px) {
//   .app-ed-container {
//     max-width: 1140px;
//   }
// }
@media (min-width: 1400px) {
  .app-ed-container {
    max-width: $app-container-max-width;
  }
}

// App Background Classes
.bg-ed-red {
  background-color: $color-ed-red;
}
.bg-ed-lt-cobald {
  background-color: $color-ed-lt-cobald;
}
.bg-ed-lt-mint {
  background-color: $color-ed-lt-mint;
}
.bg-ed-lt-olive {
  background-color: $color-ed-lt-olive;
}
.bg-ed-lt-violet {
  background-color: $color-ed-lt-violet;
}
.bg-ed-white {
  background-color: $color-ed-white;
}
.bg-ed-cold-white {
  background-color: $color-ed-cold-white;
  &.bg-opacity-25 {
    background-color: $color-ed-bg-25-cold-white;
  }
  &.bg-opacity-50 {
    background-color: $color-ed-bg-50-cold-white;
  }
  &.bg-opacity-75 {
    background-color: $color-ed-bg-75-cold-white;
  }
}
.bg-ed-gray {
  background-color: $color-ed-gray;
}
.bg-ed-lt-gray {
  background-color: $color-ed-lt-gray;
}
.bg-ed-dk-gray {
  background-color: $color-ed-dk-gray;
}
.bg-ed-blue {
  background-color: $color-ed-blue;
}

.shade:before {
  width: 3000px;
  height: 270px;
  position: absolute;
  left: -2600px;
  top: -50px;
  content: '';

  background: #b0effe;
  border-radius: 0 100px 100px 0;
  z-index: 0;
}

.shade-lg:before {
  width: 3000px;
  height: 320px;
  position: absolute;
  left: -2600px;
  top: -50px;
  content: '';

  background: #b0effe;
  border-radius: 0 100px 100px 0;
  z-index: 0;
}

.shade-modal:before {
  width: 400px;
  // height: 320px;
  position: absolute;
  // left: calc(-50px - 1rem);
  // top: -50px;
  content: '';

  background: #b0effe;
  border-radius: 0 100px 100px 0;
  z-index: 0;

  height: 268px;
  left: calc(-50px - 1rem);
  top: -20px;
}

@media all and (min-width: 1024px) and (max-width: 1365px) {
  .shade-modal:before {
    left: calc(-40px - 0rem);
    width: 350px;
    height: 285px;
    top: -25px;
  }
  .shade-lg:before {
    left: -2600px;
    height: 285px;
    top: -25px;
  }
  .shade:before {
    left: -2682px;
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .shade-lg:before {
    height: 267px;
    left: -2700px;
    top: -20px;
  }

  .shade:before {
    height: 245px;
    left: -2660px;
    top: -45px;
  }
}

@media all and (min-width: 640px) and (max-width: 1023px) {
  .shade-lg:before {
    left: -2740px;
    height: 285px;
    top: -25px;
  }
}

@media all and (min-width: 640px) and (max-width: 767px) {
  .shade-modal:before {
    width: 400px;
    height: 200px;
    position: absolute;
    left: calc(-55px - 1rem);
    top: -15px;
  }

  .shade:before {
    left: -2650px;
    height: 250px;
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .shade-modal:before {
    width: 430px;
    height: 200px;
    position: absolute;
    left: calc(-45px - 1rem);
    top: -15px;
  }

  .shade:before {
    left: -2795px;
  }
}

// App Text Color
.text-ed-red {
  color: $color-ed-red;
}
.text-ed-lt-cobald {
  color: $color-ed-lt-cobald;
}
.text-ed-lt-mint {
  color: $color-ed-lt-mint;
}
.text-ed-lt-olive {
  color: $color-ed-lt-olive;
}
.text-ed-lt-violet {
  color: $color-ed-lt-violet;
}
.text-ed-white {
  color: $color-ed-white;
}
.text-ed-cold-white {
  color: $color-ed-cold-white;
}
.text-ed-gray {
  color: $color-ed-gray;
}
.text-ed-lt-gray {
  color: $color-ed-lt-gray;
}
.text-ed-dk-gray {
  color: $color-ed-dk-gray;
}
.text-ed-blue {
  color: $color-ed-blue;
}

// App Fonts Classes
.text-ed-bold {
  font-family: 'Ubuntu Bold';
}
.text-ed-italic {
  font-family: 'Ubuntu Italic';
}
.text-ed-light {
  font-family: 'Ubuntu Light';
}
.text-ed-medium {
  font-family: 'Ubuntu Medium';
}
.text-ed-regular {
  font-family: 'Ubuntu Regular';
}

.text-50 {
  font-size: 50px;
  line-height: 50px;
}

// App Layout Support Classes
// Default border radius
.border-ed-radius {
  border-radius: $border-radius;
  & input {
    border-radius: $input-border-radius;
  }
}
.border-ed-bottom-none {
  @extend .border-ed-radius;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.border-ed-radius-lg {
  border-radius: $border-radius-lg;
}

.border-ed {
  border: $border;
  & input {
    border: $input-border;
  }
}

.border-ed-dark {
  border: $border-dark;
  & input {
    border: $input-border-dark;
  }
}

.border-ed-light {
  border: $border-light;
}

.border-ed-blue {
  border: $border-blue;
}

// Default Shadow
.box-ed-shadow {
  box-shadow: $box-shadow;
}

// ScrollBar
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: $color-ed-lt-gray;
}

// Forms

// Forms
.input-ed-fixed {
  min-height: 42px;
  // width: 100%;
  padding: $input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;
  border: $input-border;
  border-radius: $input-border-radius;
  &.valid {
    // border: $input-border-valid;
  }
  &.invalid {
    border: $input-border-invalid;
  }
}

.input-ed {
  min-height: 44px;
  // width: 100%;
  padding: $input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;
  border: $input-border;
  border-radius: $input-border-radius;
  &:focus {
    // width: 95%;
    // transition: width 0.5s ease-in-out;
    // border: $input-border-dark;
  }
  &:focus + span,
  &:focus + button {
    // right: 30px;
    // transition: right 0.5s ease-in-out;
  }
  &.valid {
    // border: $input-border-valid;
  }
  &.invalid {
    border: $input-border-invalid;
  }
}

.input-ed-sm {
  height: 41px;
  // width: 100%;
  font-size: $input-sm-text;
  padding: $input-sm-padding-top $input-sm-padding-right $input-sm-padding-bottom
    $input-sm-padding-left;
  border: $input-border;
  border-radius: $input-border-radius;
  &:focus {
    // width: 95%;
    // transition: width 0.5s ease-in-out;
    // border: $input-border-dark;
  }
  &:focus + span,
  &:focus + button {
    // right: 30px;
    // transition: right 0.5s ease-in-out;
  }
  &.valid {
    // border: $input-border-valid;
  }
  &.invalid {
    border: $input-border-invalid;
  }
}

input[type='password'] {
  // letter-spacing: 3px;
  -webkit-text-security: square;
  color: $color-ed-dk-gray;
}

// .select-ed-sm {
//   // height: 41px;
//   // width: 100%;
//   font-size: $input-sm-text;
//   padding: 15px;
//   border: $input-border;
//   border-radius: $input-border-radius;
//   &:focus {
//     width: 95%;
//     transition: width 0.5s ease-in-out;
//     border: $input-border-dark;
//   }
//   &:focus + span,
//   &:focus + button {
//     right: 30px;
//     transition: right 0.5s ease-in-out;
//   }
//   &.valid {
//     border: $input-border-valid;
//   }
//   &.invalid {
//     border: $input-border-invalid;
//   }
// }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  background: #5c6664;
  background-image: none;
  flex: 1;
  // height: 41px;
  border: $input-border;
  border-radius: $input-border-radius;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 15px;
}
select::-ms-expand {
  display: none;
}
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  line-height: 3;
  background: #5c6664;
  overflow: hidden;
  border-radius: 0.25em;
}
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: $color-ed-dk-gray;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}
// .select:hover::after {
//  color: #23b499;
// }

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-ed-lt-gray;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $color-ed-lt-gray;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $color-ed-lt-gray;
}

// // Checkbox
// input[type='checkbox']:checked + svg {
//   display: block;
// }
// input[type='checkbox'] + svg {
//   display: none;
// }

// SVG
.circle-tick {
  path {
    fill: $color-ed-circle-tick;
  }
}

// Extra
.dotted-line-with-text {
  position: relative;
}
.dotted-line-with-text span {
  display: inline-block;
  // background: #fff;
  position: relative;
  z-index: 1;
}
.dotted-line-with-text:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 35px;
  right: 0;
  z-index: 0;
  border-top: 2px dotted #6d7787;
  opacity: 0.5;
}

.dotted-vertical-line-with-text {
  position: relative;
}
.dotted-vertical-line-with-text:first-child {
  // display: inline-block;
  // background: #fff;
  position: relative;
  z-index: 1;
}
.dotted-vertical-line-with-text:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 35px;
  right: 0;
  z-index: 0;
  border-right: 2px dotted #6d7787;
  opacity: 0.5;
}
