.image-slider {
  position: relative;
  /* Slideshow container */
  .slideshow-container {
    // max-width: 1000px;
    position: relative;
    margin: auto;
  }

  /* Hide the images by default */
  // .mySlides {
  //   display: none;

  // }

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  /* Caption text */
  .text {
    color: #f2f2f2;
    padding: 8px 12px;
    position: absolute;
    bottom: 25%;
    width: 100%;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    span.w {
      text-shadow: -1px 1px rgb(0 0 0 / 75%);
    }
  }

  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #ffffff;
    opacity: 0.5;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    &.active {
      opacity: 1;
    }
  }

  // .active,
  // .dot:hover {
  //   background-color: #717171;
  // }

  .slideshow-dots {
    position: absolute;
    bottom: 60px;
    width: 100%;
    text-align: center;
  }

  /* Fading animation */
  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 0.5s;
    animation-name: fade;
    animation-duration: 0.5s;
  }

  @-webkit-keyframes fade {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .mySlides {
    img {
      min-width: 100vw;
      margin: -1rem;
    }
  }

  .image-slider .slideshow-dots {
    bottom: 45px;
  }

  .image-slider .text {
    bottom: 65px;
  }
  .image-slider .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
  }
}

@media all and (min-width: 768px) and (max-width: 900px) {
  .image-slider {
    .slideshow-dots {
      position: absolute;
      bottom: 75px;
      width: 100%;
      text-align: center;
    }
  }
}
