.voucher {
  .partner-block-voucher {
    padding-top: 15px;
    padding-bottom: 15px;
    .voucher-content {
      // margin-top: -30px;
      // margin-bottom: -15px;
      .voucher-detail {
        z-index: 0;
      }
    }
    .voucher-header {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      padding: 0 15px;
      z-index: 2;
      position: relative;
    }
  }
  .partner-block-list-voucher {
    padding-top: 10px;
    padding-bottom: 10px;
    .voucher-content {
      // margin-top: -30px;
      // margin-bottom: -15px;
    }
    .voucher-header {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      padding: 0 10px;
    }
  }
}

@media all and (min-width: 320px) and (max-width: 1024px) {
  .voucher {
    .partner-block-voucher {
      .voucher-header {
        padding: 0 10px;
      }
    }
    .partner-block-list-voucher {
      .voucher-header {
        padding: 0 10px;
      }
    }
  }
}
