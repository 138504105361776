.reset-password-ed-verification-list {
  padding: 15px 20px;
}
.reset-password-ed-card-foot {
  // padding: 15px 20px;
}
.reset-password-ed {
  .reset-password-ed-card {
    // margin-top: 35px;
    // margin-bottom: 95px;
  }
}

@media all and (min-width: 1366px) {
  .reset-password-ed {
    .reset-password-ed-card {
      width: 540px;
      padding: 15px 95px;
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .reset-password-ed {
    .reset-password-ed-card {
      width: 300px;
      padding: 15px;
      // margin-bottom: 50px;
    }
    // .reset-password-ed-card-foot {
    //   padding: 15px 5px;
    // }
  }
}

@media all and (min-width: 640px) and (max-width: 767px) {
  .reset-password-ed {
    .reset-password-ed-card {
      width: 400px;
      padding: 25px;
      // margin-bottom: 50px;
    }
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .reset-password-ed {
    .reset-password-ed-card {
      width: 450px;
      padding: 25px 50px;
    }
  }
}
@media all and (min-width: 1024px) and (max-width: 1365px) {
  .reset-password-ed {
    .reset-password-ed-card {
      width: 540px;
      padding: 25px 50px;
    }
  }
}
