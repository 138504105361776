// Edenred Red - #F72717 (Scarlet)
$color-ed-red: #f72717;
// Shades
$color-ed-red-50: #fff6f5;
$color-ed-red-100: #fedfdc;
$color-ed-red-200: #fcb1ab;
$color-ed-red-300: #fa837a;
$color-ed-red-400: #f95548;
$color-ed-red-500: #f72717;
$color-ed-red-600: #d41607;
$color-ed-red-700: #a21106;
$color-ed-red-800: #710c04;
$color-ed-red-900: #400702;

// Edenred Light Cobald - #B0EFFF (Anakiwa)
$color-ed-lt-cobald: #b0efff;
// Shades
$color-ed-lt-cobald-50: #ffffff;
$color-ed-lt-cobald-100: #ffffff;
$color-ed-lt-cobald-200: #ffffff;
$color-ed-lt-cobald-300: #ffffff;
$color-ed-lt-cobald-400: #e3f9ff;
$color-ed-lt-cobald-500: #b0efff;
$color-ed-lt-cobald-600: #7de5ff;
$color-ed-lt-cobald-700: #4adaff;
$color-ed-lt-cobald-800: #17d0ff;
$color-ed-lt-cobald-900: #00b5e3;

// Edenred Light Mint - #98F6DB (Ice Cold)
$color-ed-lt-mint: #98f6db;
// Shades
$color-ed-lt-mint-50: #ffffff;
$color-ed-lt-mint-100: #ffffff;
$color-ed-lt-mint-200: #ffffff;
$color-ed-lt-mint-300: #f6fefc;
$color-ed-lt-mint-400: #c7faeb;
$color-ed-lt-mint-500: #98f6db;
$color-ed-lt-mint-600: #69f2cb;
$color-ed-lt-mint-700: #3aeeba;
$color-ed-lt-mint-800: #14e1a6;
$color-ed-lt-mint-900: #10b284;

// Edenred Light Olive - #DCF691 (Mindaro)
$color-ed-lt-olive: #dcf691;
// Shades
$color-ed-lt-olive-50: #ffffff;
$color-ed-lt-olive-100: #ffffff;
$color-ed-lt-olive-200: #ffffff;
$color-ed-lt-olive-300: #fafeef;
$color-ed-lt-olive-400: #ebfac0;
$color-ed-lt-olive-500: #dcf691;
$color-ed-lt-olive-600: #cdf262;
$color-ed-lt-olive-700: #beee33;
$color-ed-lt-olive-800: #a8dc12;
$color-ed-lt-olive-900: #84ad0e;

// Edenred Light Violet - #DCF691 (Melrose)
$color-ed-lt-violet: #c8c3ff;
// Shades
$color-ed-lt-violet-50: #ffffff;
$color-ed-lt-violet-100: #ffffff;
$color-ed-lt-violet-200: #ffffff;
$color-ed-lt-violet-300: #ffffff;
$color-ed-lt-violet-400: #f7f6ff;
$color-ed-lt-violet-500: #c8c3ff;
$color-ed-lt-violet-600: #9990ff;
$color-ed-lt-violet-700: #6a5dff;
$color-ed-lt-violet-800: #3c2aff;
$color-ed-lt-violet-900: #1400f6;

// Edenred Light White - #FFFFFF (White)
$color-ed-white: #ffffff;
// Shades - LAMO

// Edenred Cold White - #F1F7FF (Zircon)
$color-ed-cold-white: #f1f7ff;
$color-ed-bg-cold-white: #f1f7ff;
$color-ed-bg-25-cold-white: #f1f7ff25;
$color-ed-bg-50-cold-white: #f1f7ff50;
$color-ed-bg-75-cold-white: #f1f7ff75;

// Shades
$color-ed-cold-white-50: #ffffff;
$color-ed-cold-white-100: #ffffff;
$color-ed-cold-white-200: #ffffff;
$color-ed-cold-white-300: #ffffff;
$color-ed-cold-white-400: #ffffff;
$color-ed-cold-white-500: #f1f7ff;
$color-ed-cold-white-600: #bedaff;
$color-ed-cold-white-700: #8bbdff;
$color-ed-cold-white-800: #58a0ff;
$color-ed-cold-white-900: #2582ff;

// Edenred Gray - #6D7787 (Pale-sky)
$color-ed-gray: #6d7787;
// Shades
$color-ed-gray-50: #ebecef;
$color-ed-gray-100: #dddfe3;
$color-ed-gray-200: #c0c5cd;
$color-ed-gray-300: #a4abb6;
$color-ed-gray-400: #88919f;
$color-ed-gray-500: #6d7787;
$color-ed-gray-600: #565e6b;
$color-ed-gray-700: #3f454f;
$color-ed-gray-800: #292c32;
$color-ed-gray-900: #121416;

// Edenred Light Gray - #D5D8DD (Iron)
$color-ed-lt-gray: #d5d8dd;
// Shades
$color-ed-lt-gray-50: #ffffff;
$color-ed-lt-gray-100: #ffffff;
$color-ed-lt-gray-200: #ffffff;
$color-ed-lt-gray-300: #ffffff;
$color-ed-lt-gray-400: #f1f2f4;
$color-ed-lt-gray-500: #d5d8dd;
$color-ed-lt-gray-600: #b9bec6;
$color-ed-lt-gray-700: #9da4af;
$color-ed-lt-gray-800: #808999;
$color-ed-lt-gray-900: #67707f;

// Edenred Dark Gray - #707070 (Dove-gray)
$color-ed-dk-gray: #707070;
// Shades
$color-ed-dk-gray-50: #e3e3e3;
$color-ed-dk-gray-100: #d6d6d6;
$color-ed-dk-gray-200: #bdbdbd;
$color-ed-dk-gray-300: #a3a3a3;
$color-ed-dk-gray-400: #8a8a8a;
$color-ed-dk-gray-500: #707070;
$color-ed-dk-gray-600: #575757;
$color-ed-dk-gray-700: #3d3d3d;
$color-ed-dk-gray-800: #242424;
$color-ed-dk-gray-900: #0a0a0a;

// Edenred Blue - #3A8BF7 (Dodger-blue)
$color-ed-blue: #3a8bf7;
// Shades
$color-ed-blue-50: #ffffff;
$color-ed-blue-100: #fefeff;
$color-ed-blue-200: #cde2fd;
$color-ed-blue-300: #9cc5fb;
$color-ed-blue-400: #6ba8f9;
$color-ed-blue-500: #3a8bf7;
$color-ed-blue-600: #0a6ef4;
$color-ed-blue-700: #0858c3;
$color-ed-blue-800: #064292;
$color-ed-blue-900: #042c61;

// Some Specific Colors
$color-ed-input-border-valid: #007dfa;
$color-ed-input-border-invalid: $color-ed-red;
$color-ed-circle-tick: #0d8aff;
