//  App configuration variables
@import './theme-color.scss';

// App max page width
$app-container-max-width: 1040px;

// App Fonts
$font-file: '/assets/fonts/';

// App Border
$border-size: 1px;
$border: $border-size solid $color-ed-lt-gray;
$border-dark: $border-size solid $color-ed-gray;
$border-light: $border-size solid $color-ed-lt-gray;
$border-blue: $border-size solid $color-ed-blue;
$border-radius: 15px;
$border-radius-lg: 24px;

$input-border-radius: 50px;
$input-border: $border-size solid $color-ed-lt-gray;
$input-border-dark: $border-size solid $color-ed-gray;
$input-border-valid: $border-size solid $color-ed-input-border-valid;
$input-border-invalid: $border-size solid $color-ed-input-border-invalid;

// App Box Shadow
$box-shadow: 0px 3px 6px #00000029;

// App Forms
$input-text: 18px;
$input-height: 44px;
$input-padding-top: 12px;
$input-padding-right: 20px;
$input-padding-bottom: 12px;
$input-padding-left: 20px;

$input-sm-text: 14px;
$input-sm-height: 44px;
$input-sm-padding-top: 13px;
$input-sm-padding-right: 20px;
$input-sm-padding-bottom: 13px;
$input-sm-padding-left: 20px;

// App Layout Support
$header-height: 120px;
$header-sm-height: 120px;
$header-md-height: 120px;
$header-lg-height: 120px;
$header-xl-height: 120px;
$header-xxl-height: 120px;

$footer-height: 120px;
$footer-sm-height: 120px;
$footer-md-height: 120px;
$footer-lg-height: 120px;
$footer-xl-height: 120px;
$footer-xxl-height: 120px;

$app-page-content-max-width: 1110px;
$app-page-content-xs-max-width: 320px;
$app-page-content-sm-max-width: 600px;
$app-page-content-md-max-width: 690px;
$app-page-content-lg-max-width: 930px;
$app-page-content-xl-max-width: 1110px;
$app-page-content-xxl-max-width: 1110px;

$app-page-container-max-width: 1923px;
$app-page-container-xs-max-width: 100%;
$app-page-container-sm-max-width: 100%;
$app-page-container-md-max-width: 100%;
$app-page-container-lg-max-width: 960px;
$app-page-container-xl-max-width: 1140px;

$app-page-container-padding-x: 50px;
$app-page-container-padding-y: 30px;

// App Header
$app-header-color: $color-ed-gray;
$app-header-active-color: $color-ed-red;

$app-header-bg-color: $color-ed-white;
$app-header-active-bg-color: $color-ed-red;

$app-header-hover-bg-color: $color-ed-red;
$app-header-hover-text-color: $color-ed-white;
$app-header-hover-icon-color: $color-ed-white;

// Modal
$modal-bg-color: $color-ed-bg-75-cold-white;
$modal-bg-dark-color: $color-ed-gray;
