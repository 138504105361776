.info-card {
  width: 540px;
  margin: auto;
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  img {
    max-height: 45px;
    width: auto;
  }
}

.expand-full-width {
  .voucher {
    width: 100% !important;
  }
}

.home-partner-list {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  /* standard */
  column-gap: 30px;
  row-gap: 15px;

  /* old */
  grid-column-gap: 30px;
  grid-row-gap: 15px;
}

.main-voucher {
  // width: 100vw;
  .list-voucher-item {
    padding: 0 10px;
  }
  .voucher-list-item {
    display: flex;
    .voucher-date {
      // width: 65px;
      height: auto;
      display: flex;
      padding-right: 10px;
      margin: 0;
      width: 50px;
      .date-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .voucher-list-item {
      width: calc(100% - 65px);
      padding-left: 10px;
      .voucher-trigger-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .partner-list-voucher {
    height: 75px;
  }

  .main-voucher {
    width: 98vw;
    .voucher-list-item {
      display: flex;
      .voucher-date {
        // width: 65px;
        height: auto;
        display: flex;
        padding-right: 10px;
        min-width: 40px;
        margin: 0;
        width: auto;
        .date-label {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .voucher-list-item {
        width: calc(100% - 65px);
        padding-left: 10px;
        .voucher-trigger-item {
          display: flex;
          flex-direction: column-reverse;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;
          div:first-child {
            // padding-top: 10px;
            // padding-bottom: 10px;
          }
        }
      }
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .info-card {
    width: 100%;
  }

  .home-partner-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    /* standard */
    column-gap: 10px;
    row-gap: 10px;

    /* old */
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}

@media all and (min-width: 640px) and (max-width: 767px) {
  .info-card {
    width: 85%;
  }

  .home-partner-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .info-card {
    width: 85%;
  }

  .home-partner-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media all and (min-width: 1024px) and (max-width: 1365px) {
  .info-card {
    width: 540px;
  }

  .home-partner-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    /* standard */
    column-gap: 50px;
    row-gap: 15px;

    /* old */
    grid-column-gap: 50px;
    grid-row-gap: 15px;
  }
}

@media all and (min-width: 1366px) {
  .info-card {
    width: 540px;
  }

  .home-partner-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    /* standard */
    column-gap: 30px;
    row-gap: 15px;

    /* old */
    grid-column-gap: 30px;
    grid-row-gap: 15px;
  }
}
