.voucher {
  min-width: 150px;
  width: 100%;
  min-height: fit-content;
  display: inline-block;
  // padding: 15px 25px;
  background-image: radial-gradient(
      ellipse closest-side at 50% 50%,
      hsla(0, 0%, 100%, 0.1),
      transparent 90%
    ),
    radial-gradient(circle at 0 100%, transparent 14px, #fff 15px),
    radial-gradient(circle at 100% 100%, transparent 14px, #fff 15px),
    radial-gradient(circle at 100% 0, transparent 14px, #fff 15px),
    radial-gradient(circle at 0 0, transparent 14px, #fff 15px);
  background-position:
    center center,
    bottom left,
    bottom right,
    top right,
    top left;
  background-size:
    cover,
    51% 51%,
    51% 51%,
    51% 51%,
    51% 51%;
  background-repeat: no-repeat;
  border-width: 0 4px;
  border-color: transparent;
  border-style: solid;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 1px 3px);
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 1px 3px);
}
