.onboard-view {
  padding-top: 28px;
  width: 411px;
  .onboard-img-container > img {
    width: 389px;
    height: 403px;
  }
}

@media all and (min-width: 1366px) and (max-width: 1079px) {
  .onboard-view {
    padding-top: 28px;
    width: 411px;
    .onboard-img-container > img {
      width: 290px;
      height: 300px;
    }
  }
}

@media all and (min-width: 1024px) and (max-width: 1365px) {
  .onboard-view {
    padding-top: 23px;
    width: 330px;
    .onboard-img-container > img {
      width: 290px;
      height: 300px;
    }
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .onboard-view {
    padding-top: 18px;
    width: 330px;
    .onboard-img-container > img {
      width: 300px;
      height: 300px;
    }
  }
}

@media all and (min-width: 640px) and (max-width: 767px) {
  .onboard-view {
    padding-top: 18px;
    width: 330px;
    .onboard-img-container > img {
      width: 300px;
      height: 300px;
    }
  }
}

@media all and (min-width: 320px) and (max-width: 639px) {
  .onboard-view {
    padding-top: 18px;
    width: 330px;
    .onboard-img-container > img {
      width: 300px;
      height: 300px;
    }
  }
}
